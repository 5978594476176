import { environment } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContractObject } from 'src/app/core/models/onboarding/onboarding';
import { allRegex } from 'src/app/extensions/regex';
import { OnboardingService } from 'src/app/core/services/onboarding/onboarding.service';
import { catchError, of, tap } from 'rxjs';
import { downloadFile } from 'src/app/extensions/downloadFile';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {
  public signForm!: FormGroup;
  public isVisibleCodeInput!: boolean;
  public loading!: boolean;
  environment = environment
  @Output() contractSigned = new EventEmitter<boolean>();
  @Input() contractObject!: ContractObject;
  @Input() orderId: string;


  constructor(private formBuilder: FormBuilder, private onboardingService: OnboardingService) {
    this.signForm = this.formBuilder.group({
      dataProtectionOwnCompany: [false],
      dataProtectionThirdCompanies: [false],
      aceptacionCondiciones: [false, [Validators.requiredTrue]],
      code: ["", [Validators.required, Validators.maxLength(6), Validators.minLength(6)]], //code
    })
  }

  ngOnInit(): void {
    console.log(environment.environmentUrl + this.contractObject.contractUrl)
  }
  downloadFile(): void {
    this.onboardingService.downloadContract(this.contractObject.contractUrl, 'blob').pipe(
      tap((res) => {
        downloadFile(res, `contrato_prestamo_${this.orderId}`)
      }),
      catchError((error) => {
        return of(null)
      })
    ).subscribe()
  }

  sendSmsCode() {
    this.loading = true;
    this.onboardingService.sendContractCode('CONFIA', this.orderId).pipe(
      tap((res) => {
        this.isVisibleCodeInput = true
      }),
      catchError((error) => {
        this.signForm.get('code').setErrors({ errorSend: 'Hubo un error al enviar el código. Inténtalo de nuevo más tarde.' })
        return of(null)
      })
    ).subscribe(res => this.loading = false)
  }

  signContract() {
    if (!this.signForm.valid) {
      this.signForm.get('aceptacionCondiciones').setErrors({ requiredTrue: 'Es necesario aceptar las condiciones generales para continuar' })
    } else {
      this.loading = true;
      this.onboardingService.verifyContractCode('CONFIA', this.orderId, this.signForm.value).pipe(
        tap((res) => {
          setTimeout(() => {
            this.contractSigned.emit(true)
          }, 1000);
        }),
        catchError((error) => {
          this.signForm.get('code').setErrors({ invalidCode: 'El código introducido no es correcto' })
          return of(null)
        })
      ).subscribe(res => this.loading = false)
    }

  }
}
