import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContractObject, Onboarding, OnboardingResponse } from '../../models/onboarding/onboarding';
import { OnboardingState } from '../../models/onboarding/onboardintState';
import { OnboardingAbstractService } from './onboarding.abstract-service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService implements OnboardingAbstractService {

  constructor(private http:
    HttpClient){

  }
  public decode(merchant: string, data: any): Observable<Onboarding> {
    return this.http.post<Onboarding>(`${environment.baseUrl}/merchant/${merchant}/decode`, data)
  }

  public onboarding(onboarding: Onboarding): Observable<OnboardingResponse> {
    return this.http.post<OnboardingResponse>(`${environment.baseUrl}/onboarding`, onboarding)
  }

  public state(orderId: string): Observable<OnboardingState> {
    const header =  { apiKey: `confia.onboarding.key` }
    return this.http.get<OnboardingState>(`${environment.baseUrl}/onboarding/CONFIA/order/${orderId}/state`, { headers: header } )
  }

  public analyze(orderId: string): Observable<void> {
    const header =  { apiKey: `confia.onboarding.key` }
    return this.http.post<void>(`${environment.baseUrl}/onboarding/CONFIA/order/${orderId}/analyze`, null, { headers: header } )
  }

  //Contract endpoints

  public getContract(merchantId: string, orderId: string): Observable<ContractObject> {
    const header =  { apiKey: `confia.onboarding.key` }
    return this.http.post<ContractObject>(`${environment.baseUrl}/onboarding/${merchantId}/order/${orderId}/generateLoanInstallments`, null, { headers: header } )
  }

  public sendContractCode(merchantId: string, orderId: string): Observable<void> {
    const header =  { apiKey: `confia.onboarding.key` }
    return this.http.put<void>(`${environment.baseUrl}/onboarding/${merchantId}/order/${orderId}/sendContractCode`,null, { headers: header } )
  }

  public verifyContractCode(merchantId: string, orderId: string, body:any): Observable<void> {
    const header =  { apiKey: `confia.onboarding.key` }
    return this.http.put<void>(`${environment.baseUrl}/onboarding/${merchantId}/order/${orderId}/verifyContractCode`, body, { headers: header } )
  }

  downloadContract(downloadUrl: string, responseType:any): Observable<Blob> {
    const header =  { apiKey: `confia.onboarding.key` }
    return this.http.get<Blob>(`${environment.baseUrl}${downloadUrl}`, { headers: header, responseType: responseType} )
  }
}
