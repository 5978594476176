<div class="container_section">
  <div class="title_section">
    <mat-icon>description</mat-icon>
  <h2>Firma de contrato</h2>
</div>
<div class="text_container">
  <p class="std-text">Lee los documentos legales siguientes con detenimiento y firma si estás de acuerdo con las condiciones.</p>
</div>
<div class="pdf-render">
  <pdf-viewer [src]="'data:application/pdf;base64,'+contractObject.contract" [original-size]="false" [zoom-scale]="'page-width'" [show-all]="true"
  style="width: 550px; height: 400px;max-width: 100vw;max-height: 100%;border-radius: 6px"></pdf-viewer>
</div>
  <button mat-stroked-button color="accent" (click)="downloadFile()"> <mat-icon>download</mat-icon> Descargar Contrato</button>

    <div class="form_container" >
        <ng-container *ngIf="isVisibleCodeInput">
            <p class="title_form">Introduce el código que has recibido por SMS en tu teléfono y pulsa en el botón "Firmar".</p>
            <form class="sign_form" id="formFirmas" [formGroup]="signForm" novalidate >
                <!-- <app-toolbar-alerts *ngIf="error != null" [status]="'ko'" [alertMessage]="error"></app-toolbar-alerts> -->
                <div class="check_container">
                    <mat-checkbox name="dataProtectionOwnCompany" formControlName="dataProtectionOwnCompany"></mat-checkbox>
                    <p>Autorizo el tratamiento de mis datos personales para el envío de ofertas comerciales relativas a productos financieros y de seguros de Unión Financiera Asturiana, S.A., EFC</p>
                </div>
                <div class="check_container">
                    <mat-checkbox name="dataProtectionThirdCompanies" formControlName="dataProtectionThirdCompanies"></mat-checkbox>
                    <p>Autorizo el envío de ofertas comerciales por parte de terceras empresas de los sectores de la actividad especificadas en Información Básica sobre Protección de Datos.</p>
                </div>
                <div class="check_container">
                    <mat-checkbox name="aceptacionCondiciones" formControlName="aceptacionCondiciones"></mat-checkbox>
                    <p> Acepto las <a href="https://www.confia.com.es/condiciones-generales">Condiciones Generales</a>, las <a href="https://www.confia.com.es/condiciones-entidad-pago">Condiciones Generales de la entidad de pagos Lemonway</a>, las <a href="https://www.confia.com.es/condiciones-uso">Condiciones de Uso</a> y la <a href="https://www.confia.com.es/politica-privacidad">Politica de Privacidad</a></p>
                </div>
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>Código Titular</mat-label>
                    <input matInput formControlName="code" name="code" maxlength="6">
                </mat-form-field>
                <!-- <div *ngIf="_creditData.codigoFirma != '' || _creditData.codigoFirma != null">
                    <p> Código: {{ _creditData.codigoFirma }}</p>
                </div> -->
                <div class="btn_container">
                    <mat-error *ngIf="signForm.get('code').hasError('invalidCode')">El código introducido no es correcto.</mat-error>
                    <mat-error *ngIf="signForm.get('aceptacionCondiciones').hasError('requiredTrue')">Es necesario aceptar las condiciones generales para continuar</mat-error>
                    <button mat-stroked-button color="accent" (click)="signContract()" [disabled]="!signForm.get('code').valid || loading"> <span *ngIf="!loading">Firmar</span><mat-spinner *ngIf="loading" [diameter]="25"></mat-spinner>  </button>
                </div>
            </form>
            <div  class="footer_form">
                <p>Si tienes alguna duda, <a href="">puedes contactarnos</a>.</p>
            </div>
        </ng-container>

        <ng-container *ngIf="!isVisibleCodeInput">
            <div  id="sendSignatureCodeSMS" class="sendSMS_button">
                <button mat-stroked-button color="accent" (click)="sendSmsCode()" [disabled]="loading"> <span *ngIf="!loading">Enviar SMS de firma</span><mat-spinner *ngIf="loading" [diameter]="25"></mat-spinner> </button>
                <mat-error *ngIf="signForm.get('code').hasError('errorSend')">{{signForm.get('code').getError('errorSend')}}</mat-error>
            </div>
        </ng-container>
    </div>
</div>
  
<!-- <div *ngIf="loader" class="skeleton_container">s
    <ngx-skeleton-loader
    count="1"
    [theme]="{ 'border-radius': '0', height: '400px' }"
  ></ngx-skeleton-loader>
</div> -->



