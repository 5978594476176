import { Component, forwardRef, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    },
     {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    }
  ]
})
export class AddressComponent implements OnInit, ControlValueAccessor, Validators {
  addressForm = this.fb.group({
    state: [null, Validators.required],
    county: [null, Validators.required],
    city: [null, Validators.required],
    address: [null, Validators.required],
    address2: null,
    postalCode: [null, Validators.compose([
      Validators.required, Validators.minLength(5), Validators.maxLength(5)])
    ]
  });

  hasUnitNumber = false;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {

  }

  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    val && this.addressForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    console.log("on change");
    this.addressForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    console.log("on blur");
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.addressForm.disable() : this.addressForm.enable();
  }

  validate(c: AbstractControl): ValidationErrors | null{
    console.log("ADress validation");
    return this.addressForm.valid ? null : { invalidForm: {valid: false, message: "Address fields are invalid"}};
  }

  onSubmit(): void {
    alert('Thanks!');
  }

  
  showCounties(e: any): any {
    this.counties = this.provinces.filter(county => {
      county.region == this.addressForm.controls['state'].value
    })
  }

  states = [
    { id: 'ES-AN', name: 'Andalucía' },
    { id: 'ES-AR', name: 'Aragón' },
    { id: 'ES-AS', name: 'Asturias' },
    { id: 'ES-CN', name: 'Canarias' },
    { id: 'ES-CB', name: 'Cantabria' },
    { id: 'ES-CM', name: 'Castilla La Mancha' },
    { id: 'ES-CL', name: 'Castilla y León' },
    { id: 'ES-CT', name: 'Cataluña' },
    { id: 'ES-EX', name: 'Extremadura' },
    { id: 'ES-GA', name: 'Galicia' },
    { id: 'ES-IB', name: 'Islas baleares' },
    { id: 'ES-RI', name: 'La Rioja' },
    { id: 'ES-MD', name: 'Madrid' },
    { id: 'ES-MC', name: 'Murcia' },
    { id: 'ES-NC', name: 'Navarra' },
    { id: 'ES-PV', name: 'País Vasco' },
    { id: 'ES-VC', name: 'Comunidad Valenciana' },
    { id: "ES-CE", name: 'Ceuta'},
    { id: "ES-ML", name: 'Melilla'}
  ];

  counties: any[] = []

  provinces = [
    {"code": "ES-C", "name": "A Coruña", "postal":  "15", "region":  "ES-GA"},
    {"code": "ES-VI", "name": "Álava", "postal":  "01", "region":  "ES-PV"},
    {"code": "ES-AB", "name": "Albacete", "postal":  "02", "region":  "ES-CM"},
    {"code": "ES-A", "name": "Alicante", "postal":  "03", "region":  "ES-VC"},
    {"code": "ES-AL", "name": "Almería", "postal":  "04", "region":  "ES-AN"},
    {"code": "ES-O", "name": "Asturias", "postal":  "33", "region":  "ES-AS"},
    {"code": "ES-AV", "name": "Ávila", "postal":  "05", "region":  "ES-CL"},
    {"code": "ES-BA", "name": "Badajoz", "postal":  "06", "region":  "ES-EX"},
    {"code": "ES-PM", "name": "Baleares", "postal":  "07", "region":  "ES-I"},
    {"code": "ES-B", "name": "Barcelona", "postal":  "08", "region":  "ES-CT"},
    {"code": "ES-BI", "name": "Vizcaya", "postal":  "48", "region":  "ES-PV"},
    {"code": "ES-BU", "name": "Burgos", "postal":  "09", "region":  "ES-CL"},
    {"code": "ES-CC", "name": "Cáceres", "postal":  "10", "region":  "ES-EX"},
    {"code": "ES-CA", "name": "Cádiz", "postal":  "11", "region":  "ES-AN"},
    {"code": "ES-S", "name": "Cantabria", "postal":  "39", "region":  "ES-CB"},
    {"code": "ES-CS", "name": "Castellón", "postal":  "12", "region":  "ES-VC"},
    {"code": "ES-CR", "name": "Ciudad Real", "postal":  "13", "region":  "ES-CM"},
    {"code": "ES-CO", "name": "Córdoba", "postal":  "14", "region":  "ES-AN"},
    {"code": "ES-CU", "name": "Cuenca", "postal":  "16", "region":  "ES-CM"},
    {"code": "ES-SS", "name": "Gipuzkoa", "postal":  "20", "region":  "ES-PV"},
    {"code": "ES-GI", "name": "Girona", "postal":  "17", "region":  "ES-CT"},
    {"code": "ES-GR", "name": "Granada", "postal":  "18", "region":  "ES-AN"},
    {"code": "ES-GU", "name": "Guadalajara", "postal":  "19", "region":  "ES-CM"},
    {"code": "ES-H", "name": "Huelva", "postal":  "21", "region":  "ES-AN"},
    {"code": "ES-HU", "name": "Huesca", "postal":  "22", "region":  "ES-AR"},
    {"code": "ES-J", "name": "Jaén", "postal":  "23", "region":  "ES-AN"},
    {"code": "ES-LO", "name": "La Rioja", "postal":  "26", "region":  "ES-RI"},
    {"code": "ES-GC", "name": "Las Palmas", "postal":  "35", "region":  "ES-CN"},
    {"code": "ES-LE", "name": "León", "postal":  "24", "region":  "ES-CL"},
    {"code": "ES-L", "name": "Lleida", "postal":  "25", "region":  "ES-CT"},
    {"code": "ES-LU", "name": "Lugo", "postal":  "27", "region":  "ES-GA"},
    {"code": "ES-M", "name": "Madrid", "postal":  "28", "region":  "ES-MD"},
    {"code": "ES-MA", "name": "Málaga", "postal":  "29", "region":  "ES-AN"},
    {"code": "ES-MU", "name": "Murcia", "postal":  "30", "region":  "ES-MC"},
    {"code": "ES-NA", "name": "Navarra", "postal":  "31", "region":  "ES-NC"},
    {"code": "ES-OR", "name": "Ourense", "postal":  "32", "region":  "ES-GA"},
    {"code": "ES-P", "name": "Palencia", "postal":  "34", "region":  "ES-CL"},
    {"code": "ES-PO", "name": "Pontevedra", "postal":  "36", "region":  "ES-GA"},
    {"code": "ES-SA", "name": "Salamanca", "postal":  "37", "region":  "ES-CL"},
    {"code": "ES-TF", "name": "Santa Cruz de Tenerife", "postal":  "38", "region":  "ES-CN"},
    {"code": "ES-SG", "name": "Segovia", "postal":  "40", "region":  "ES-CL"},
    {"code": "ES-SE", "name": "Sevilla", "postal":  "41", "region":  "ES-AN"},
    {"code": "ES-SO", "name": "Soria", "postal":  "42", "region":  "ES-CL"},
    {"code": "ES-T", "name": "Tarragona", "postal":  "43", "region":  "ES-CT"},
    {"code": "ES-TE", "name": "Teruel", "postal":  "44", "region":  "ES-AR"},
    {"code": "ES-TO", "name": "Toledo", "postal":  "45", "region":  "ES-CM"},
    {"code": "ES-V", "name": "Valencia", "postal":  "46", "region":  "ES-VC"},
    {"code": "ES-VA", "name": "Valladolid", "postal":  "47", "region":  "ES-CL"},
    {"code": "ES-ZA", "name": "Zamora", "postal":  "49", "region":  "ES-CL"},
    {"code": "ES-Z", "name": "Zaragoza", "postal":  "50", "region":  "ES-AR"},
    {"code": "ES-CE", "name": "Ceuta", "postal":  "51", "region":  "ES-CE"},
    {"code": "ES-ML", "name": "Melilla", "postal":  "52", "region":  "ES-ML"}
  ] 
}
