import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard.service';
import { LoginWrapperComponent } from './shared/layout/login/login.wrapper.component';
import { FaqComponent } from './modules/faq/faq.component';
import { LandingComponent } from './modules/landing/landing/landing.component';
import { AddressComponent } from './shared/layout/address-form/address.component';
import { WebsocketComponent } from './shared/layout/websocket/websocket.component';
import { PublicGuard } from './core/services/public.guard';
import { ContractComponent } from './modules/onboarding/contract/contract.component';

const routes: Routes = [
  { path: '', component: LandingComponent, canActivate: [PublicGuard]},
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]},
  { path: 'shopping', loadChildren: () => import('./modules/shopping/shopping.module').then(m => m.ShoppingModule), canActivate: [AuthGuard]},
  { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard]},
  { path: 'help', loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule) },
  { path: 'onboarding', loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule)},
  { path: 'payment', loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule), canActivate: [AuthGuard]},
  { path: 'loginByEmail', component: LoginWrapperComponent},
  { path: 'faqs', component: FaqComponent},
  { path: 'address', component: AddressComponent},
  { path: 'websocket', component: WebsocketComponent},
  {path: 'test', component: ContractComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
